<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2 class="primary--text mt-16 text-center" v-if="!verificationComplete">
          Verifying...
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VERIFY_EMAIL, LOAD_ORGANIZATION } from "@/store/_actiontypes";


export default {
  data(){
    return {
      verificationComplete: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
    }),
  },
  created(){
    this.verifyResetPassword();
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
  },
  methods: {
    ...mapActions("account", [VERIFY_EMAIL]),
    ...mapActions("account", [LOAD_ORGANIZATION]),

    verifyResetPassword(){
      if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
        setTimeout(function (){
          if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined'){
            this.verifyResetPassword();
          } else {
            grecaptcha.execute(this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {action: 'verify_email'}).then((recaptcha_token) => {
              this.verifyResetPasswordLink(recaptcha_token);
            });
          }
        }.bind(this), 10);
      } else{
        this.verifyResetPasswordLink(null);
      }
    },
    verifyResetPasswordLink(recaptcha_token){
      this.verificationComplete = false;

      this.VERIFY_EMAIL({
        token: this.$route.params.token,
        recaptcha_token: recaptcha_token
      }).then((res)=>{
        if(res.status===200){
          this.verificationComplete = true;
          
          if(this.user){
            this.LOAD_ORGANIZATION().then(res => {
              this.$router.push("/setup-accounts");
            });
          }
        }
      },(error) => {
        this.verificationComplete = true;
        this.$router.push("/404");
      }).catch(err=>{
        this.verificationComplete = true;
        this.$router.push("/404");
      });
    }
  }
};
</script>
